import { Global, css } from '@emotion/core';
import React from 'react';
import { darken } from 'polished';
import { useTheme } from '@emotion/react';

export const GlobalStyles = () => {
  const theme = useTheme();

  theme.colors.background.color = '#efefef';
  theme.colors.primary.color = '#D00005';
  theme.colors.secondary.color = '#fff';
  theme.colors.secondary.onColor = '#000';
  theme.colors.default.color = 'rgba(67,54,47,0.5)';
  theme.input.color = '#000';
  theme.header.background = '#fff';
  theme.header.color = '#000';
  theme.footer.background = '#111';
  theme.footer.color = '#fff';
  theme.default.padding = 15;
  theme.card.marginBottom = 30;
  theme.card.paddingTop = 30;
  theme.card.paddingBottom = 30;
  theme.card.paddingHorizontal = 45;
  theme.container.smWidth;
  theme.button.paddingHorizontal = 18;
  theme.button.paddingVertical = 12;
  theme.input.paddingHorizontal = 18;
  theme.input.paddingVertical = 12;
  theme.default.borderRadius = 4;

  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
        }

        body {
          margin: 0;
          padding: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: ${theme.colors.background.color};
          color: ${theme.colors.background.onColor};
          font-weight: 500;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 0.6px;
          position: relative;
          font-family: Roboto, sans-serif;
          z-index: 1;
        }

        .main {
          min-height: 80vh;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        fieldset {
          border: none;
          padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 900;
          line-height: normal;
        }

        h1 {
          font-size: 2em;
          /* font-size: 30px; */
          margin-top: 40px;
          margin-bottom: 10px;
        }

        h2 {
          font-size: 1.5em;
          /* font-size: 24px; */
          margin-top: 17px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 1.17em;
          /* font-size: 24px; */
          margin-top: 17px;
          margin-bottom: 10px;
        }

        h4 {
          margin-top: 10px;
          margin-bottom: 5px;
        }

        p {
          margin-top: 5px;
          margin-bottom: 10px;
        }

        @media (min-width: 576px) {
          /* h1 {
            font-size: 45px;
          }

          h2 {
            font-size: 30px;
          }

          h3 {
            font-size: 25px;
          } */
        }

        a {
          color: ${theme.colors.primary.color};
          text-decoration: none;
          cursor: pointer;
        }

        label {
          font-weight: 700;
          margin-top: 10px;
          color: ${darken(0.2, theme.colors.primary.color)};
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: 26px;
          padding: 0;
        }

        fieldset {
          border: 1px solid #ddd;
          padding-block-start: 0.35em;
          padding-inline-start: 0.75em;
          padding-inline-end: 0.75em;
          padding-block-end: 0.625em;
          margin-bottom: 30px;
          background: rgba(0, 0, 0, 0.01);
        }

        legend {
          color: #888;
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          width: auto;
          padding-right: 5px;
          padding-left: 5px;
          margin: 0 -5px;
        }

        pre {
          white-space: normal;
        }
      `}
    />
  );
};
