import React, { FC } from 'react';
import { Section, SectionProps } from '@nimles/react-web-components';
import Graphic from '../svg/Background';
import styled from '@emotion/styled';
import { ScrollDown } from '../ScrollDown';

export const Content = styled(Section)`
  position: relative;
  padding: 70px 0 0;
  flex: 1 0 auto;
`;

export const StyledSection = styled(Section)`
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.banner?.primary?.onColor};
  position: relative;
  p {
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 10px;
  }
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    text-align: left;
  }
`;

export const Filler = styled.div`
  position: relative;
  flex: 0 0 100%;
  background-color: ${({ theme }) => theme.banner?.primary?.color};
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    flex: 1;
  }
`;

export const ImageBackground = styled(Section)`
  display: flex;
  flex: 1;
  order: 1;
  margin-left: -100%;
  z-index: -1;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    margin-left: -30%;
  }
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    margin-left: -300px;
  }
`;

export const GraphicBackground = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.banner?.primary?.color};
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    background-color: transparent;
    display: flex;
    width: calc(200px + 50%);
  }
  @media (min-width: ${({ theme }) => theme.thresholds.lg}px) {
    width: calc(100px + 50%);
  }
  @media (min-width: ${({ theme }) => theme.thresholds.xl}px) {
    width: calc(50px + 50%);
  }
  @media (min-width: ${({ theme }) => theme.thresholds.xxl}px) {
    width: calc(100px + 50%);
  }
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
`;

const StyledGraphic = styled(Graphic)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    position: relative;
  }
`;

interface Props {
  backgroundImage?: string;
  backgroundPosition?: string;
  graphic?: boolean;
  padding?: boolean | number | string;
  minHeight?: string;
}

export const BannerSection: FC<Props> = ({
  backgroundImage,
  backgroundPosition,
  children,
  graphic = true,
  minHeight,
  ...props
}) => {
  return (
    <StyledSection {...props}>
      <BackgroundContainer>
        <GraphicBackground>
          <Filler />
          <StyledGraphic />
        </GraphicBackground>
        <ImageBackground
          backgroundPosition={backgroundPosition}
          backgroundImage={backgroundImage}
        />
      </BackgroundContainer>
      <Content>{children}</Content>
    </StyledSection>
  );
};
