import { useTheme } from '@emotion/react';
import * as React from 'react';

function Graphic(props) {
  const theme = useTheme();

  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 320 320"
      height="auto"
      preserveAspectRatio="none"
      {...props}
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="50%"
        y1="100%"
        x2="100%"
        y2="100%"
      >
        <stop offset="0%" stopColor={theme.banner.primary.color} />
        <stop offset="100%" stopColor={theme.banner.secondary.color} />
      </linearGradient>
      <path
        fill={theme.banner.primary.color}
        d="M-8.32667268e-15 0L300 0 260 320 -8.32667268e-15 320z"
      />
      <path
        fill="url(#a)"
        d="M-3.01980663e-13 0L70 0 320 320 -5.95079541e-13 320z"
      />
    </svg>
  );
}

export default Graphic;
